import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as MagnifyingGlassLoading } from 'images/magnifying-glass.svg';
import { ReactComponent as BankLoading } from 'images/bank-loader.svg';
import StateContainer from 'components/StateContainer';
import { useSelector } from 'react-redux';
import { getLoanOffer } from 'selectors/getLoanOfferData';
import { getApplicationData } from 'selectors/getApplicationData';
import { getApplicationData as getApplicationDataThunk } from 'thunks';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { RoutePath } from 'enums/Routes';
import { ErrorType } from 'components/Error/ErrorType';
import { LoanOfferStep } from 'api/LoanOfferApi';
import { getCheckupProgress, getProgressText } from 'components/FinancialCheckup/Analyzing/Analyzing';
import { FlowComponentType } from 'routes/FlowRouter';

import styles from './Loader.module.scss';

const Loader = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const [progress, setProgress] = useState(0);
  const [accountSync, setAccountSync] = useState(false);
  const [totalAccounts, setTotalAccounts] = useState(0);

  const offer = useSelector(getLoanOffer);
  const { application, isLoading: isLoadingApplicaiton } = useSelector(getApplicationData);

  useEffect(() => {
    const run = async () => {
      if (
        offer.isCompleted &&
        offer.response.data.application_id &&
        !isLoadingApplicaiton &&
        application === undefined
      ) {
        const app = await dispatchWithUnwrap(getApplicationDataThunk(offer.response.data.application_id!));
        handleNext(app.application.hardOffer !== undefined);
      }
      if (!offer.isLoading && (offer.isError || (offer.isCompleted && !offer.response.passed))) {
        navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
      }
      if (offer.isLoading && offer.progress) {
        setProgress(getCheckupProgress(offer.progress));
        if (offer.progress.step === LoanOfferStep.GettingTradelines && offer.progress.totalAccounts) {
          setAccountSync(true);
          setTotalAccounts(offer.progress.totalAccounts || 0);
        }
      }
    };
    run();
  }, [offer]);

  return (
    <div className={styles.container}>
      <StateContainer
        icon={accountSync ? <BankLoading /> : <MagnifyingGlassLoading />}
        progress={progress}
        title={getProgressText(offer.progress!, accountSync, totalAccounts)}
      />
    </div>
  );
};

export default Loader;
